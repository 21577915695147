<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"><div class="ui-type-display-lg">Prodotti</div></CCol>
      <CCol sm="auto">
        <CButton color="primary" @click="goToProductCreateForm()">
          CREA NUOVO PRODOTTO
        </CButton>
      </CCol>
    </CRow>
    <ProductsTableList />
  </div>
</template>

<script>
import ProductsTableList from "./ProductsTableList";

export default {
  name: "Products",

  components: {
    ProductsTableList,
  },

  methods: {
    goToProductCreateForm() {
      this.$router.push({
        name: "RoleProductsCreate",
        params: { id: this.$store.state.role.id },
      });
    },
  },
};
</script>
